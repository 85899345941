import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { holderContext } from "../components/holder-authentication/HolderAuthentication";
import { useWallet } from "@solana/wallet-adapter-react";

const ProtectedRoute = (props) => {
	const { mbbHolder } = useContext(holderContext);
	const { connected } = useWallet();

	// Navigate to login page if a user is not connected or does not hold an MBB NFT and is trying to access a protected route
	return connected ? (
		mbbHolder ? (
			<Outlet {...props} />
		) : (
			<Navigate to="/login" />
		)
	) : (
		<Navigate to="/login" />
	);
};

export default ProtectedRoute;
