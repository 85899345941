import React from "react";
import { IoClose } from "react-icons/io5";
import { NavLink } from "react-router-dom";

import {
	TOOLS_DROPDOWN,
	MONKEY_BABY_DROPDOWN,
	HOLDERS_DROPDOWN,
} from "../../values/dropdowns";

import DropdownMobileMenu from "./DropdownMobileMenu";

export const MobileFullscreenMenu = ({ setViewMobileMenu }) => {
	return (
		<div className="fixed w-screen h-screen overflow-y-auto pb-24 z-[100] lg:hidden bg-white">
			<div className="flex flex-col justify-center items-center pt-6 pb-20 w-full">
				<div className="flex flex-row justify-start w-full px-6 mb-8">
					<button
						onClick={() => {
							setViewMobileMenu(false);
						}}
						className="flex lg:hidden mr-4 text-dark-blue "
					>
						<IoClose size={32} />
					</button>
				</div>

				<DropdownMobileMenu
					title={"Holders Map"}
					dropdownItems={HOLDERS_DROPDOWN}
					externalLinks={false}
					{...{ setViewMobileMenu }}
				/>

				<DropdownMobileMenu
					title={"Monkey Babies"}
					dropdownItems={MONKEY_BABY_DROPDOWN}
					externalLinks={true}
					{...{ setViewMobileMenu }}
				/>

				<DropdownMobileMenu
					title={"Tools"}
					dropdownItems={TOOLS_DROPDOWN}
					externalLinks={true}
					{...{ setViewMobileMenu }}
				/>
			</div>
		</div>
	);
};

export default MobileFullscreenMenu;
