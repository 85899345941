import React, { useState } from "react";
import { createContext, useEffect } from "react";
import { verifyMbbHolder } from "../../services/features/mbb-verification";
import { useWallet } from "@solana/wallet-adapter-react";
import { fetchHolderProfile } from "../../services/features/profile-view";
import {
	getAuth,
	signInAnonymously,
	signInWithCustomToken,
	signOut,
} from "firebase/auth";

import { getFunctions, httpsCallable } from "firebase/functions";

// import { functions } from "firebase-functions";
// import { firebase } from "firebase";
// import functions from 'firebase-functions';

/** Used to keep track of whether the wallet holds an MBB across components */
export const holderContext = createContext({
	mbbHolder: false,
	newAccount: false,
	initConfig: false,
});

/** Component that contains the logic needed to verify if a holder has an MBB */
export const HolderAuthentication = ({ children }) => {
	const { connected, publicKey } = useWallet();
	const [mbbHolder, setMbbHolderState] = useState(false);
	const [newAccount, setNewAccountState] = useState(false);
	const [initConfig, setInitConfig] = useState(false); // Needed to prevent the page from redirecting before newAccount is set
	const [configPublicKey, setConfigPublicKey] = useState(""); // Needed to prevent the page from redirecting before newAccount is set

	const auth = getAuth();

	/** Used to determine whether a wallet based on its public key holds an MBB */
	useEffect(() => {
		if (publicKey != configPublicKey && connected && publicKey?.toBase58()) {
			auth.signOut(); // signs the user out anytime they disconnect or change their public key
			setMbbHolderState(false);
			configAuth();
			setConfigPublicKey(publicKey);
		}
	}, [connected, publicKey]);

	const configAuth = () => {
		setInitConfig(false);
		if (publicKey != configPublicKey) {
			verifyMbbHolder(publicKey?.toBase58()).then((holderStatus) => {
				// Set their MBB holder status
				setMbbHolderState(holderStatus);

				// If they hold an MBB
				if (holderStatus == true) {
					const onUserRequest = httpsCallable(getFunctions(), "onUserRequest");
					onUserRequest({
						reqString: "bNP87gUs2zOYdZ0sW4qFRTlLa90",
						user: publicKey.toBase58(),
					})
						.then((payload) => {
							const { data } = payload;
							if (data) {
								signInWithCustomToken(auth, data)
									.then(() => {
										// Sign in.
										fetchHolderProfile(publicKey?.toBase58()).then(
											(holderInfo) => {
												// Redirect them to the profile page if they haven't set a profile photo or don't have an account
												if (
													holderInfo == null ||
													holderInfo?.profile_photo == ""
												) {
													setNewAccountState(true);
												} else {
													// Redirect them to the map page if they have an account
													setNewAccountState(false);
												}
												setInitConfig(true);
											}
										);
									})
									.catch((error) => {
										setMbbHolderState(false);
										setInitConfig(true);
										return;
									});
							} else {
								// If the user doesn't have a uid, create a new one.
								signInAnonymously(auth)
									.then((credential) => {
										console.log("anon user signed in - ", credential.user.uid);
										setNewAccountState(true); // always put the user to the profile page if they sign in anonymously
										setInitConfig(true);
									})
									.catch((error) => {
										console.log("anon user error - ", error);
										setMbbHolderState(false);
										setInitConfig(true);
										return;
									});
							}
						})
						.catch((error) => {
							setMbbHolderState(false);
							setInitConfig(true);
							return;
						});
				} else {
					// If the user is not an MBB holder, ensures they are signed out.
					signOut(auth);
					setInitConfig(true);
				}
			});
		}
	};

	return (
		<holderContext.Provider
			value={{
				mbbHolder,
				newAccount,
				initConfig,
			}}
		>
			{children}
		</holderContext.Provider>
	);
};

export default HolderAuthentication;
