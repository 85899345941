import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { NavLink } from "react-router-dom";

export const DropdownMenu = ({ title, dropdownItems, externalLinks }) => {
	const [viewDropdown, setViewDropdown] = useState(false);

	return (
		<div
			className="p-4"
			onMouseLeave={() => {
				setViewDropdown(false);
			}}
		>
			<div className="relative inline-block text-left">
				<button
					onMouseEnter={() => {
						setViewDropdown(true);
					}}
					className="inline-flex flex-row justify-center w-full px-2 py-2 focus:outline-none"
				>
					<div className="flex flex-row items-center text-dark-blue">
						<p className="mr-1 font-cubano">{title}</p>
						<IoIosArrowDown size={18} />
					</div>
				</button>
				{viewDropdown && (
					<div className="absolute left-0 w-56 mt-2 origin-top-left bg-white  divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black  ring-opacity-5 focus:outline-none z-[300]">
						<div className="py-1">
							{dropdownItems.map((item, index) => (
								<>
									{externalLinks ? (
										<a
											href={item.url}
											target={`${item.currentSite ? "" : "_blank"}`}
											className={`flex flex-row px-4 py-2 text-sm   hover:bg-gray-100 hover:text-pink  
											${item.currentSite ? `text-pink` : `text-gray-700`}`}
											key={index}
										>
											{item?.icon && item?.icon != "" && (
												<img
													style={{
														width: "20px",
														height: "20px",
														marginRight: "6px",
														objectFit: "contain",
													}}
													src={item.icon}
												/>
											)}
											{item.label}
										</a>
									) : (
										<NavLink
											to={item.url}
											className={({ isActive }) =>
												`flex flex-row px-4 py-2 text-sm  hover:bg-gray-100 hover:text-pink   ${
													isActive ? `!text-pink` : `text-dark-blue `
												}`
											}
											key={index}
										>
											{item?.icon && item?.icon != "" && (
												<img
													style={{
														width: "20px",
														height: "20px",
														marginRight: "6px",
														objectFit: "contain",
													}}
													src={item.icon}
												/>
											)}
											{item.label}
										</NavLink>
									)}
								</>
							))}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default DropdownMenu;
