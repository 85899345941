import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import MbbLogo from "../../assets/images/monkey_baby_business_logo.png";
import { FiMenu } from "react-icons/fi";
import {
	TOOLS_DROPDOWN,
	MONKEY_BABY_DROPDOWN,
	HOLDERS_DROPDOWN,
} from "../../values/dropdowns";

import DropdownMenu from "./DropdownMenu";
import useWindowDimensions from "../utils/Dimensions";
import MobileFullscreenMenu from "./MobileFullscreenMenu";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

export const UniversalHeader = () => {
	const [viewMobileMenu, setViewMobileMenu] = useState(false);
	const windowDimensions = useWindowDimensions();

	useEffect(() => {
		if (windowDimensions.width <= 1024) {
			if (viewMobileMenu) {
				document.body.style.position = "fixed";
			} else {
				document.body.style.position = "static";
			}
			console.log(
				"document.body.style.position - ",
				document.body.style.position
			);
		}
	}, [viewMobileMenu]);

	useEffect(() => {
		if (windowDimensions.width > 1024 && viewMobileMenu == true) {
			setViewMobileMenu(false);
			document.body.style.position = "static";
		}
	}, [windowDimensions]);

	return (
		<>
			{!viewMobileMenu || windowDimensions.width > 1024 ? (
				<div>
					<div className="flex flex-col justify-center font-cubano items-center shadow-md  px-10 py-4 md:py-0 md:flex-row md:h-24 bg-white ">
						<div className="flex flex-row justify-center lg:justify-start items-center w-1/4">
							<div className="absolute left-6 lg:hidden">
								<button
									onClick={() => {
										setViewMobileMenu(true);
									}}
									className="flex lg:hidden text-gray-600 "
								>
									<FiMenu size={26} />
								</button>
							</div>

							<NavLink
								to={"/"}
								className="flex flex-row items-center pl-6 md:pl-0 lg:mr-4"
							>
								<img
									src={MbbLogo}
									width={135}
									height={50}
									className="min-h-[55px] min-w-[135px]"
									alt="Monkey Baby Business logo"
								/>
							</NavLink>
						</div>

						<div className="lg:flex hidden justify-center items-center md:w-2/4 mt-4 md:mt-0">
							<DropdownMenu
								title={"Holders Map"}
								dropdownItems={HOLDERS_DROPDOWN}
								externalLinks={false}
							/>

							<DropdownMenu
								title={"Monkey Babies"}
								dropdownItems={MONKEY_BABY_DROPDOWN}
								externalLinks={true}
							/>

							<DropdownMenu
								title={"Tools"}
								dropdownItems={TOOLS_DROPDOWN}
								externalLinks={true}
							/>
						</div>
						<div className="mt-4 lg:mt-0">
							<WalletMultiButton />
						</div>
					</div>
				</div>
			) : (
				<>
					<MobileFullscreenMenu
						{...{
							setViewMobileMenu,
						}}
					/>
				</>
			)}
		</>
	);
};

export default UniversalHeader;
