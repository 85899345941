import React from "react";
import { useState, useEffect } from "react";
import {
	editHolderData,
	fetchHolderProfile,
	newHolderProfile,
	addUidToHolderProfile,
} from "../../services/features/profile-view";
import { AppHeader } from "../header/Header";
import "react-toggle/style.css";
import Toggle from "react-toggle";
import { Oval } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WithContext as ReactTags } from "react-tag-input";
import "./ReactTags.css";

import firebase from "firebase/compat/app";

/** Web3 integration */
import { useWallet } from "@solana/wallet-adapter-react";
import { useMetaplex } from "../metaplex-connection/useMetaplex";

/** Mapbox integration */
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { mapboxConfig } from "../../mapboxConfig";

mapboxgl.accessToken = mapboxConfig.accessToken;

const KeyCodes = {
	comma: 188,
	enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const ProfilePage = () => {
	const { metaplex } = useMetaplex();
	const { publicKey } = useWallet();

	const [profileData, setProfileData] = useState([]);
	const [locationVisibleToggle, setLocationVisibleToggle] = useState(false);
	const [profilePhoto, setProfilePhoto] = useState("");
	const [profileMintAddress, setProfileMintAddress] = useState("");
	const [nfts, setNfts] = useState([]);
	const [loadingPage, setLoadingPage] = useState(true);
	const [loadingNfts, setLoadingNfts] = useState(false);
	const [geocoderAdded, setGeocoderAdded] = useState(false);
	const [hashlist, setHashlist] = useState([]);

	const displayFields = [
		"name",
		"description",
		"twitter_username",
		"discord_username",
		"github_username",
	];

	const geocoder = new MapboxGeocoder({
		accessToken: mapboxgl.accessToken,
		mapboxgl: mapboxgl,
	});

	const [tags, setTags] = useState([{ id: "MBB", text: "MBB" }]);
	const [maxTags, setMaxTags] = useState(false);

	useEffect(() => {
		fetchHashlist();
	}, []);

	useEffect(() => {
		if (publicKey) {
			initHoldersData();
		}
	}, [publicKey]);

	useEffect(() => {
		if (publicKey && hashlist.length > 0 && loadingNfts == false) {
			setLoadingNfts(true);
			fetchOwnersNft();
		}
	}, [publicKey, hashlist]);

	useEffect(() => {
		if (profileData == null) {
			// profileData will only be null if no holder document was returned from Firestore
			// Get MBB NFTs owned by the user and set first NFT as the profile photo
			// when creating their account
			// Also sets the associated uid to the user
			console.log("profileData == null");
			newHolderProfile(
				publicKey.toBase58(),
				firebase.auth().currentUser.uid
			).then((response) => {
				console.log("profileData newHolderProfile response -", response);
				if (response == true) {
					initHoldersData(); // re-initialize the profile data
				}
			});
		} else if (
			profileData != [] &&
			!profileData?.anon_uid &&
			profileData?.id != null
		) {
			console.log("else if profileData != []");
			addUidToHolderProfile(
				profileData.id,
				firebase.auth().currentUser.uid
			).then((response) => {
				console.log("profileData else if response -", response);
				if (response == true) {
					initHoldersData(); // re-initialize the profile data
				}
			});
		}
	}, [profileData]);

	// If the holder only has 1 NFT or the holder has no profile photo, set the first NFT as the profile photo
	useEffect(() => {
		if ((profilePhoto == "-" || nfts?.length == 1) && nfts?.length > 0) {
			let temp = profileData;
			temp.profile_photo = nfts[0]?.collectionDetails?.image;
			temp.profile_mint_address = nfts[0]?.mintAddress?.toBase58();
			setProfileData(temp);
			setProfilePhoto(nfts[0]?.collectionDetails?.image);
			setProfileMintAddress(nfts[0]?.mintAddress?.toBase58());
		}
	}, [profilePhoto, nfts]);

	/** Used to fetch the MBB mint hashlist to know the mint addresses of all MBB NFTs.
	 * TODO: move this function into the services section */
	const fetchHashlist = async () => {
		try {
			const response = await fetch("../../hashlist/mbb-hashlist.json");
			const json = await response.json();
			setHashlist(json);
		} catch (error) {
			console.log("error - ", error);
		}
	};

	/** Used to initialize the display of the holder's profile data */
	const initHoldersData = async () => {
		console.log("initHoldersData()");
		let holderData = await fetchHolderProfile(publicKey.toBase58());
		setProfileData(holderData);
		if (holderData == null) return;
		setLocationVisibleToggle(
			holderData?.location_visible ? holderData.location_visible : false
		);
		setProfilePhoto(
			holderData?.profile_photo != "" ? holderData?.profile_photo : "-"
		);
		setProfileMintAddress(
			holderData?.profile_mint_address != null
				? holderData?.profile_mint_address
				: "-"
		);
		if (holderData?.skills != null && holderData?.skills.length > 0) {
			const tagsConfig = [];
			for (let index = 0; index < holderData?.skills.length; index++) {
				tagsConfig.push({
					id: holderData?.skills[index],
					text: holderData?.skills[index],
				});
			}
			setTags(tagsConfig);
		}
		setLoadingPage(false);
	};

	/** Once geocoder element is initialized, it will add it to the location input element */
	useEffect(() => {
		addLocationInput();
	}, [geocoder, publicKey]);

	/** Used to generate location input box (with geocoding setup to recommend locations & generate longitude and latitude) */
	const addLocationInput = () => {
		const geocoderContainer = document.getElementById("geocoder");
		if (!geocoderAdded && geocoder && geocoderContainer) {
			geocoder.addTo(geocoderContainer);
			geocoder.setPlaceholder(profileData.location || "");
			setGeocoderAdded(true);
		}
	};

	/** Used to set the inputted location, longitude and latitude fields to the profile data
	 * when the user changes their location */
	geocoder.on("result", function (e) {
		if (e.result.place_name && e.result.center.length == 2) {
			let temp = profileData;
			temp.location = e.result.place_name;
			temp.longitude = e.result.center[0];
			temp.latitude = e.result.center[1];
			setProfileData(temp);
		}
	});

	/** Used to update the profile data when the user changes a field */
	const handleChange = (fieldType, e) => {
		e.preventDefault();
		let temp = profileData;
		temp[fieldType] = e.target.value;
		setProfileData(temp);
	};

	/** Used to toggle the user's location visibility on the map */
	const editLocationVisibility = () => {
		let temp = profileData;
		temp.location_visible = !temp.location_visible;
		setLocationVisibleToggle(!locationVisibleToggle);
		setProfileData(temp);
	};

	/** Set holder's profile image  */
	const editProfileImage = (selectedImage, mintAddress) => {
		let temp = profileData;
		temp.profile_photo = selectedImage;
		temp.profile_mint_address = mintAddress;
		setProfilePhoto(selectedImage);
		setProfileMintAddress(mintAddress);
		setProfileData(temp);
	};

	/** Used to save the user's changes to their profile */
	const saveChanges = async () => {
		const saveFieldChanges = await editHolderData(profileData, tags);
		if (saveFieldChanges) {
			// notify user that their changes were successfully saved
			toast("Saved changes.", {
				theme: "dark",
				position: "bottom-center",
				autoClose: 1000,
			});
		} else {
			// notify user that there was an error saving their changes
			toast("Error saving changes. Please try again", {
				theme: "dark",
				position: "bottom-center",
				autoClose: 1000,
			});
		}
	};

	/** Used to fetch a holder's NFTs to display as options for their profile photo. */
	const fetchOwnersNft = async () => {
		if (!publicKey) return;

		try {
			let ownersAddress = publicKey.toBase58();
			// Hardcoded for testing purposes
			// if (
			// 	publicKey.toBase58() == "DvRAPzHWUtjXmk7SaPQTAD1mruLtEpvn5jmwjmeh9aCP"
			// ) {
			// 	ownersAddress = "5KAwFNLcf4US4QrwfXvvA2CcbCLYNCagXz7AQ7EW1Baq"; // "6RRSBbLcJAnA4FAjdMVnAYKwzF81Z9Dtd79xDut1hT6K"
			// }
			let allNFTs = await metaplex.nfts().findAllByOwner({
				owner: ownersAddress,
			});
			let mbbNFTs = await filterOnlyMbbNfts(allNFTs);

			if (mbbNFTs != null && mbbNFTs.length > 0) {
				for (let index = 0; index < mbbNFTs.length; index++) {
					try {
						mbbNFTs[index].collectionDetails = await fetchOffchainData(
							mbbNFTs[index].uri
						);
						if (index == mbbNFTs.length - 1) {
							setNfts(mbbNFTs);
							setLoadingNfts(false);
						}
					} catch (error) {
						setLoadingNfts(false);
						console.log("error - ", error);
					}
				}
			}
		} catch (error) {
			setLoadingNfts(false);
			console.log("error - ", error);
		}
	};

	/** Used to fetch the offchain data tied to each NFT. */
	const fetchOffchainData = (uri) => {
		return new Promise((resolve, reject) => {
			try {
				const offchainData = fetch(uri)
					.then((response) => response.json())
					.then((data) => {
						return data;
					});
				resolve(offchainData);
			} catch (error) {
				reject("error - ", error);
			}
		});
	};

	/** Used to filter out all NFTs that are not from the MBB collection */
	const filterOnlyMbbNfts = (allNFTs) => {
		try {
			let mbbNFTs = [];
			if (hashlist.length > 0 && allNFTs.length > 0) {
				for (let i = 0; i < allNFTs.length; i++) {
					if (
						allNFTs[i]?.mintAddress?.toBase58() &&
						hashlist.includes(allNFTs[i]?.mintAddress?.toBase58())
					) {
						mbbNFTs.push(allNFTs[i]);
					}
				}
				return mbbNFTs;
			} else {
				return [];
			}
		} catch (error) {
			return null;
		}
	};

	const handleDelete = (i) => {
		setTags(tags.filter((tag, index) => index !== i));
	};

	const handleAddition = (tag) => {
		if (tags.length < 8) {
			setTags([...tags, tag]);
			setMaxTags(false);
		} else {
			setMaxTags(true);
		}
	};

	const handleDrag = (tag, currPos, newPos) => {
		const newTags = tags.slice();

		newTags.splice(currPos, 1);
		newTags.splice(newPos, 0, tag);

		// re-render
		setTags(newTags);
	};

	const handleTagClick = (index) => {
		console.log("The tag at index " + index + " was clicked");
	};

	return (
		<div>
			<div className="flex flex-col text-center justify-center items-center py-2">
				{!publicKey || profileData == null ? (
					<h1 className="text-2xl font-cubano font-bold pt-6">No Profile</h1>
				) : (
					<div>
						<h1 className="text-2xl font-cubano font-bold pt-6">
							Edit Profile
						</h1>

						{loadingPage ? (
							<div className="pt-6">
								<Oval
									height={40}
									width={40}
									color="#e41e8e"
									wrapperStyle={{ display: "flex", justifyContent: "center" }}
									visible={true}
									ariaLabel="oval-loading"
									secondaryColor="#e41e8e"
									strokeWidth={2}
									strokeWidthSecondary={2}
								/>
							</div>
						) : (
							<div className="flex flex-col text-center justify-center items-center">
								<p className="text-md pt-4 italic pb-8">
									{publicKey &&
										"Wallet Address: " +
											publicKey.toBase58().substring(0, 4) +
											"..." +
											publicKey
												.toBase58()
												.substring(publicKey.toBase58().length - 4)}
								</p>
								<div className="flex flex-col mb-4 text-left w-full">
									<p className="mx-4 my-1" style={{ width: 100 }}>
										Profile Photo
									</p>
									{loadingNfts ? (
										<div className="flex flex-row items-center justify-center w-full">
											<Oval
												height={40}
												width={40}
												color="#e41e8e"
												wrapperStyle={{
													display: "flex",
													justifyContent: "center",
												}}
												visible={true}
												ariaLabel="oval-loading"
												secondaryColor="#e41e8e"
												strokeWidth={2}
												strokeWidthSecondary={2}
											/>
										</div>
									) : (
										<div className="flex flex-row items-center justify-center text-left flex-wrap w-full max-w-md">
											{nfts && nfts.length > 0 ? (
												nfts.map((nft, index) => {
													return (
														<button
															onClick={() =>
																editProfileImage(
																	nft?.collectionDetails?.image,
																	nft?.mintAddress?.toBase58()
																)
															}
															key={index}
														>
															<img
																className={
																	profilePhoto == nft?.collectionDetails?.image
																		? "m-1 border-4 border-pink"
																		: "m-1"
																}
																width="100"
																height="100"
																src={
																	nft?.collectionDetails?.image
																		? nft.collectionDetails.image
																		: "https://creator-hub-prod.s3.us-east-2.amazonaws.com/unknownabstracts_pfp_1650063070880.png"
																}
																alt={nft?.name + " NFT image"}
															/>
														</button>
													);
												})
											) : (
												<div className="my-4">
													<p>No NFTs Available</p>
												</div>
											)}
										</div>
									)}
								</div>

								{/** Fetches data via Firestore, display as input boxes, user can edit - data */}
								{Object.keys(displayFields) &&
									Object.keys(displayFields).map((elem, index) => {
										return (
											<div
												className="flex flex-row items-center my-2 text-left"
												key={elem}
											>
												<p className="capitalize pl-2" style={{ width: 100 }}>
													{displayFields[elem].replaceAll("_", " ")}
												</p>
												{displayFields[elem] != "description" && (
													<input
														className="border rounded-lg p-2 ml-6 text-black placeholder-black"
														defaultValue={profileData[displayFields[elem]]}
														style={{ width: 250 }}
														onChange={(event) =>
															handleChange(displayFields[elem], event)
														}
													/>
												)}
												{displayFields[elem] == "description" && (
													<textarea
														className="border rounded-lg p-2 ml-6 text-black placeholder-black"
														defaultValue={profileData[displayFields[elem]]}
														style={{ width: 250 }}
														onChange={(event) =>
															handleChange(displayFields[elem], event)
														}
													/>
												)}
											</div>
										);
									})}

								<div className="flex flex-row my-4 text-left">
									<p className="capitalize pl-2 pt-2" style={{ width: 100 }}>
										Skills/Interests
									</p>
									<div className="ml-6 font-sans">
										<div style={{ maxWidth: 250, flexWrap: "wrap" }}>
											<ReactTags
												tags={tags}
												delimiters={delimiters}
												handleDelete={handleDelete}
												handleAddition={handleAddition}
												handleDrag={handleDrag}
												handleTagClick={handleTagClick}
												inputFieldPosition="top"
												placeholder="Add a skill/interest & press enter"
											/>
										</div>
										{maxTags && (
											<p
												className="pt-2 text-pink font-semibold"
												style={{ maxWidth: 250, flexWrap: "wrap" }}
											>
												Note: Max of 8 skills/interests can be added.
											</p>
										)}
									</div>
								</div>

								<div className="flex flex-row items-center my-4 text-left">
									<p className="capitalize pl-2" style={{ width: 100 }}>
										Location
									</p>
									<div
										id="geocoder"
										className="ml-6"
										style={{ width: 250 }}
									></div>
								</div>

								<div className="flex flex-row items-center mt-4 mb-2 text-left">
									<p className="capitalize mr-4">Show Location on Map</p>
									<Toggle
										id="location_visible"
										icons={false}
										checked={locationVisibleToggle}
										onChange={() => {
											editLocationVisibility();
										}}
									/>
								</div>

								<button
									onClick={() => {
										saveChanges();
									}}
									className="block rounded-xl py-2 mb-16 px-4 m-6 truncate border border-pink bg-pink text-white font-semibold"
								>
									Save Changes
								</button>
							</div>
						)}
					</div>
				)}
			</div>
			<ToastContainer />
		</div>
	);
};

export default ProfilePage;
