import { COLLECTION_NAMES } from "../../values/constants";
import { getCollectionReference } from "../firebase.service";

/**
 * Used to fetch all MBB holders data from Firestore
 * @returns an array of holders' data.
 */
export const getAllHolders = () => {
	return new Promise((resolve, reject) => {
		try {
			const holders = [];

			/** Creates a reference to the MBB holders collection in Firestore */
			const holdersRef = getCollectionReference(COLLECTION_NAMES.MBB_HOLDERS);

			holdersRef.get().then(async (instance) => {
				if (instance.size > 0) {
					instance.forEach((collection) => {
						const holdersData = collection.data();
						if (holdersData.location_visible && holdersData.active_holder) {
							holders.push(holdersData);
						}
					});

					resolve(holders);
				} else {
					// no MBB holder documents found
					resolve(null);
				}
			});
		} catch (error) {
			console.log("getAllHolders | error", error);
			reject(error);
		}
	});
};

/**
 * Used to fetch all MBB holders data from Firestore
 * taking into account duplicate locations and adjusting the longitude and latitude values
 * @returns an array of holders' data.
 */
export const getAllHoldersSpiralCoords = (step = 0.125) => {
	return new Promise((resolve, reject) => {
		try {
			const holders = [];

			/** Creates a reference to the MBB holders collection in Firestore */
			const holdersRef = getCollectionReference(COLLECTION_NAMES.MBB_HOLDERS);

			holdersRef.get().then(async (instance) => {
				if (instance.size > 0) {
					instance.forEach((collection) => {
						const holdersData = collection.data();
						if (
							holdersData.location_visible &&
							holdersData.active_holder &&
							holdersData.profile_photo != "" &&
							holdersData.profile_photo != "missing" &&
							(holdersData.longitude != 0 || holdersData.latitude != 0) &&
							holdersData.location != ""
						) {
							let skillListString = "";
							if (holdersData?.skills && holdersData?.skills?.length > 0) {
								for (
									let index = 0;
									index < holdersData.skills.length;
									index++
								) {
									if (holdersData.skills.length == 1) {
										skillListString += holdersData.skills[index] + ".";
									} else if (index == holdersData.skills.length - 1) {
										skillListString += "& " + holdersData.skills[index] + ".";
									} else {
										skillListString += holdersData.skills[index] + ", ";
									}
								}
							}

							holders.push({
								...holdersData,
								id: collection.id,
								skills_display: skillListString,
								skills_display_shortened:
									skillListString.length > 35
										? skillListString.substring(0, 31) + "..."
										: skillListString,
								encoded_profile_photo: encodeURI(holdersData.profile_photo),
							});
						}
					});

					const spiralCoords = await spiralMatrixCoords(holders, step);

					resolve(spiralCoords);
				} else {
					// no MBB holder documents found
					resolve(null);
				}
			});
		} catch (error) {
			console.log("getAllHolders | error", error);
			reject(error);
		}
	});
};

// Used to create a spiral matrix when multiple holders have the same location
// Credit: https://stackoverflow.com/questions/398299/looping-in-a-spiral
const spiralMatrixCoords = (holders, step) => {
	return new Promise((resolve, reject) => {
		try {
			// Find all longitude and latitude values that are the same
			const duplicatesVals = [];
			let latCoords = [];
			let lonCoords = [];
			for (let i = 0; i < holders.length; i++) {
				if (
					latCoords.includes(holders[i].latitude) &&
					lonCoords.includes(holders[i].longitude)
				) {
					duplicatesVals.push({
						longitude: holders[i].longitude,
						latitude: holders[i].latitude,
					});
				} else {
					latCoords.push(holders[i].latitude);
					lonCoords.push(holders[i].longitude);
				}
			}

			// Find all holders that have the same longitude and latitude values
			const duplicateHolders = [];
			for (let i = 0; i < holders.length; i++) {
				if (
					duplicatesVals.some(
						(e) =>
							e.longitude == holders[i].longitude &&
							e.latitude == holders[i].latitude
					)
				) {
					duplicateHolders.push({ ...holders[i], index: i });
				}
			}

			// Adjust holder longitude and latitude values for those that have the same location
			for (let i = 0; i < duplicatesVals.length; i++) {
				let distance = 0;
				let range = 1;
				let direction = "up";
				let x = duplicatesVals[i].longitude;
				let y = duplicatesVals[i].latitude;

				for (let j = 0; j < duplicateHolders.length; j++) {
					if (
						duplicateHolders[j].longitude == duplicatesVals[i].longitude &&
						duplicateHolders[j].latitude == duplicatesVals[i].latitude
					) {
						holders[duplicateHolders[j].index].longitude = x;
						holders[duplicateHolders[j].index].latitude = y;
						distance++;
						switch (direction) {
							case "up":
								y += step;
								if (distance >= range) {
									direction = "right";
									distance = 0;
								}
								break;
							case "right":
								x += step;
								if (distance >= range) {
									direction = "bottom";
									distance = 0;
									range += 1;
								}
								break;
							case "bottom":
								y -= step;
								if (distance >= range) {
									direction = "left";
									distance = 0;
								}
								break;
							case "left":
								x -= step;
								if (distance >= range) {
									direction = "up";
									distance = 0;
									range += 1;
								}
								break;
							default:
								break;
						}
					}
				}
			}
			resolve(holders);
		} catch (error) {
			console.log("spiralMatrixCoords | error", error);
			reject(error);
		}
	});
};
