/* eslint-disable @typescript-eslint/no-explicit-any */
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

/**
 * Creates collection snapshot instance. Used to access MBB holders collection data.
 * @param collectionName String. Collection name
 */
export const getCollectionReference = (collectionName) => {
	return firebase.firestore().collection(collectionName);
};
