export const COLLECTION_NAMES = {
	MBB_HOLDERS: "mbb_holders",
};

export const SOLANA_RPC_ENDPOINT = {
	METAPLEX: "https://api.metaplex.solana.com/",
	QUICKNODE:
		"https://billowing-skilled-owl.solana-mainnet.discover.quiknode.pro/67ca8c24185da7bb3abdf577ced16267063e69ff/",
	HELIUS: "https://allix-w7mqid-fast-mainnet.helius-rpc.com/",
};
