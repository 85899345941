import { COLLECTION_NAMES } from "../../values/constants";
import { getCollectionReference } from "../firebase.service";

/**
 * Used to fetch a MBB holder's own specific profile
 * @returns an array containing all of the holders' data.
 */
export const fetchHolderProfile = (publicKey) => {
	return new Promise((resolve, reject) => {
		try {
			/** Creates a reference to the MBB holders collection in Firestore */
			const holdersRef = getCollectionReference(COLLECTION_NAMES.MBB_HOLDERS);

			holdersRef
				.where("public_key", "==", publicKey)
				.limit(1)
				.get()
				.then(async (instance) => {
					if (instance.size > 0) {
						instance.forEach((collection) => {
							const id = collection.id;
							const holderData = collection.data();
							resolve({ id, ...holderData });
						});
					} else {
						// No holder data associated to the wallet address was found in Fireestore
						resolve(null);
					}
				});
		} catch (error) {
			console.log("getAllHolders | error", error);
			reject(error);
		}
	});
};

/**
 * Used by an MBB holder to edit their individual holder data.
 * @returns whether the data was successfully edited
 */
export const editHolderData = (holderData, tags) => {
	return new Promise((resolve, reject) => {
		try {
			const {
				description,
				location,
				longitude,
				latitude,
				location_visible,
				name,
				profile_photo,
				profile_mint_address,
				twitter_username,
				discord_username,
				github_username,
				id,
			} = holderData;

			const skills = [];
			if (tags && tags.length > 0) {
				tags.forEach((tag) => {
					skills.push(tag.text);
				});
			}

			/** Creates a reference to the MBB holders collection in Firestore */
			const holdersRef = getCollectionReference(COLLECTION_NAMES.MBB_HOLDERS);

			/** Updates the holders profile data based on their input */
			holdersRef.doc(id).update({
				description: description || "",
				location: location || "",
				longitude: longitude || 0,
				latitude: latitude || 0,
				location_visible: location_visible || false,
				name: name || "",
				profile_photo: profile_photo || "",
				profile_mint_address: profile_mint_address || "",
				twitter_username: twitter_username || "",
				discord_username: discord_username || "",
				github_username: github_username || "",
				skills: skills || [],
			});

			resolve(true);
		} catch (error) {
			console.log("editHolderData | error", error);
			reject(error);
		}
	});
};

/**
 * Used to create a new MBB holder document to hold their individual holder data.
 * @returns whether the data was successfully created
 */
export const newHolderProfile = (address, anon_uid) => {
	return new Promise((resolve, reject) => {
		try {
			/** Creates a reference to the MBB holders collection in Firestore */
			const holdersRef = getCollectionReference(COLLECTION_NAMES.MBB_HOLDERS);

			/** Used to add holder document */
			holdersRef.add({
				active_holder: true,
				description: "",
				discord_username: "",
				github_username: "",
				last_checked: new Date(),
				latitude: 0,
				longitude: 0,
				location: "",
				location_visible: false,
				name: "",
				profile_photo: "",
				public_key: address,
				twitter_username: "",
				anon_uid: anon_uid,
			});

			resolve(true);
		} catch (error) {
			console.log("newHolderProfile | error", error);
			reject(error);
		}
	});
};

/**
 * Updates MBB holder document to add their uid
 * @returns whether the data was successfully created
 */
export const addUidToHolderProfile = (id, anon_uid) => {
	return new Promise((resolve, reject) => {
		try {
			/** Creates a reference to the MBB holders collection in Firestore */
			const holdersRef = getCollectionReference(COLLECTION_NAMES.MBB_HOLDERS);

			/** Updates the holders profile data based on their input */
			holdersRef.doc(id).update({
				anon_uid: anon_uid,
			});

			resolve(true);
		} catch (error) {
			console.log("addUidToHolderProfile | error", error);
			reject(error);
		}
	});
};
