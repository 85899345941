import { Metaplex } from "@metaplex-foundation/js";
import { Connection } from "@solana/web3.js";
import { SOLANA_RPC_ENDPOINT } from "../../values/constants";

/** Used to fetch the MBB mint hashlist to know the mint addresses of all MBB NFTs
 *  which is needed to see if a wallet holds a MBB NFT (based on looking at the mint address)
 *  @returns the MBB mint hashlist
 */
export const getMbbHashlist = async () => {
	try {
		const response = await fetch("../../hashlist/mbb-hashlist.json");
		const json = await response.json();
		return json;
	} catch (error) {
		console.log("getMbbHashlist error - ", error);
	}
};

/**
 * Used to verify if a wallet holds a MBB NFT.
 * @returns boolean. t/f whether the wallet is verified to have a MBB NFT
 */
export const verifyMbbHolder = (ownersAddress) => {
	return new Promise(async (resolve, reject) => {
		try {
			// Getting MBB Hashlist
			const mbbHashlist = await getMbbHashlist();

			if (!ownersAddress || !mbbHashlist) resolve(false);

			const allNFTs = await fetchNfts(ownersAddress);

			if (allNFTs == null) resolve(false);

			// Checks if any of the NFTs are MBB NFTs
			const holdingMbb = await holdingMbbNfts(allNFTs, mbbHashlist);

			resolve(holdingMbb);
		} catch (error) {
			console.log("verifyMbbHolder | error", error);
			reject(error);
		}
	});
};

const fetchNfts = async (ownersAddress) => {
	return new Promise(async (resolve, reject) => {
		try {
			const mxConnection = new Connection(SOLANA_RPC_ENDPOINT.HELIUS);
			const mx = Metaplex.make(mxConnection);

			let interval = setInterval(async () => {
				//console.log("setInterva;");
				let allNFTs = await mx.nfts().findAllByOwner({
					owner: ownersAddress,
					// ownersAddress == "DvRAPzHWUtjXmk7SaPQTAD1mruLtEpvn5jmwjmeh9aCP"
					// 	? "3UpF1CZFxqbVRmSsGRMQEknbva2Uvuduuoe3HKQ39YYh"
					// 	: ownersAddress,
				});
				clearInterval(interval);
				resolve(allNFTs);
			}, 3500);

			setTimeout(() => {
				clearInterval(interval);
				resolve(null);
				return;
			}, 15000);

			let allNFTs = await mx.nfts().findAllByOwner({
				owner: ownersAddress,
				//  == "DvRAPzHWUtjXmk7SaPQTAD1mruLtEpvn5jmwjmeh9aCP"
				// 	? "3UpF1CZFxqbVRmSsGRMQEknbva2Uvuduuoe3HKQ39YYh"
				// 	: ownersAddress,
			});
			clearInterval(interval);
			resolve(allNFTs);
			return;
		} catch (error) {
			console.log("holdingMbbNfts | error", error);
			reject(error);
		}
	});
};

/** Iterates through all NFTs to see if any of them are MBB NFTs
 *  based on their mint address.
 *  @returns boolean. t/f whether a wallet has any MBB NFTs.
 */
const holdingMbbNfts = (allNFTs, mbbHashlist) => {
	return new Promise(async (resolve, reject) => {
		try {
			if (mbbHashlist.length > 0 && allNFTs.length > 0) {
				for (let i = 0; i < allNFTs.length; i++) {
					if (
						allNFTs[i]?.mintAddress?.toBase58() &&
						mbbHashlist.includes(allNFTs[i]?.mintAddress?.toBase58())
					) {
						resolve(true);
					}
				}
			} else {
				resolve(false);
			}

			resolve(false);
		} catch (error) {
			console.log("holdingMbbNfts | error", error);
			reject(error);
		}
	});
};
