import React from "react";
import { useState, useEffect, useRef } from "react";
import { getAllHoldersSpiralCoords } from "../../services/features/holders-map";
import { AppHeader } from "../header/Header";
import MapPin from "../../assets/images/map_pin.png";
import useWindowDimensions from "../utils/Dimensions";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

/** Mapbox integration */
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { mapboxConfig } from "../../mapboxConfig";
mapboxgl.accessToken = mapboxConfig.accessToken;

export const MapPage = () => {
	const mapContainer = useRef(null);
	const map = useRef(null);
	const [mbbHolders, setMbbHolders] = useState([]);
	const [profileImagesLoaded, setProfileImagesLoaded] = useState(false);
	const [pinImageLoaded, setPinImageLoaded] = useState(false);
	const windowDimensions = useWindowDimensions();
	const [mapLoaded, setMapLoaded] = useState(false);
	const [clustersLoaded, setClustersLoaded] = useState(false);

	const [lng, setLng] = useState(-30.9);
	const [lat, setLat] = useState(20.35);
	const [zoom, setZoom] = useState(2);

	/** Initializes map on page load and fetches MBB holders */
	useEffect(() => {
		if (map.current) return; // initialize map only once
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			projection: "mercator",
			style: "mapbox://styles/mapbox/streets-v12",
			center: [lng, lat], // longitude, latitude
			zoom: zoom,
			minZoom: windowDimensions.width <= 768 ? 0 : 1.5, // used to adjust the minimum they can zoom out on the globe
			dragRotate: false,
		});
		map.current.on("load", () => {
			map.current.touchZoomRotate.disableRotation();
			map.current.addControl(
				new mapboxgl.NavigationControl({
					showCompass: false,
					showZoom: true,
				})
			);
			setMapLoaded(true);
		});
		loadHolders();
	}, []);

	// Window resize listener
	useEffect(() => {
		if (mbbHolders.length === 0) return;
		if (!mapLoaded) return;
		let mobile = windowDimensions.width <= 768;
		// load mobile pin image layer
		if (mobile && pinImageLoaded) {
			// add the pins on mobile
			initializeClusters();
			addPinLayer(mobile);
		} else if (!mobile && profileImagesLoaded) {
			// add the pins on desktop
			initializeClusters();
			addPinLayer(mobile);
		}
	}, [
		windowDimensions,
		mbbHolders,
		pinImageLoaded,
		mapLoaded,
		profileImagesLoaded,
	]);

	/** Loads in all MBB holders from Firestore converting duplicate coordinates
	 * using spiral adjustment technique */
	const loadHolders = () => {
		getAllHoldersSpiralCoords().then((response) => {
			setMbbHolders(response);
		});
	};

	/** Used to preload pin image needed for MBB holder markers */
	useEffect(() => {
		if (map.current) {
			if (!map.current.hasImage("pin") && windowDimensions.width <= 768)
				map.current.loadImage(MapPin, (error, image) => {
					if (error) return alert("error:", error);
					if (!map.current.hasImage("pin")) map.current.addImage("pin", image);
					setPinImageLoaded(true);
				});
		}
	}, []);

	/** Used to preload profile photo images needed for MBB holder markers */
	useEffect(() => {
		if (map.current && mbbHolders.length > 0) {
			for (let i = 0; i < mbbHolders.length; i++) {
				if (
					mbbHolders[i].profile_photo != "" &&
					mbbHolders[i].profile_photo != "missing"
				) {
					map.current.loadImage(
						"https://res.cloudinary.com/dsssgubl9/image/fetch/w_200,h_200,c_fill/" +
							mbbHolders[i].encoded_profile_photo,
						(error, image) => {
							if (error) {
								throw error;
							} else {
								map.current.addImage(mbbHolders[i].profile_photo, image);
								if (i === mbbHolders.length - 1) {
									setProfileImagesLoaded(true);
								}
							}
						}
					);
				}
			}
		}
	}, [mbbHolders]);

	const initializeClusters = () => {
		if (clustersLoaded) return;
		const pointsGen = mbbHolders.map((holder, index) => ({
			type: "Feature",
			properties: {
				cluster: false,
				name: holder.name,
				location: holder.location,
				profile_photo: holder.profile_photo,
				description: holder.description,
				twitter_username: holder.twitter_username,
				github_username: holder.github_username,
				discord_username: holder.discord_username,
				skills_display: holder.skills_display || "",
				id: index,
			},
			geometry: {
				type: "Point",
				coordinates: [holder.longitude, holder.latitude],
			},
		}));

		const points = {
			type: "FeatureCollection",
			crs: {
				type: "name",
				properties: {
					name: "urn:ogc:def:crs:OGC:1.3:CRS84",
				},
			},
			features: pointsGen,
		};

		// Add a new source from our GeoJSON data and
		// set the 'cluster' option to true. GL-JS will
		// add the point_count property to your source data.
		map.current.addSource("mbb_holders", {
			type: "geojson",
			// Point to GeoJSON data.
			data: points, //"https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson",
			cluster: true,
			clusterMaxZoom: 14, // Max zoom to cluster points on
			clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
			clusterMinPoints: 3,
			tolerance: 0.1,
		});

		map.current.addLayer({
			id: "clusters",
			type: "circle",
			source: "mbb_holders",
			filter: ["==", "cluster", true],
			paint: {
				"circle-color": [
					"step",
					["get", "point_count"],
					"#D61080",
					100,
					"#D61080",
					750,
					"#D61080",
				],
				"circle-radius": ["step", ["get", "point_count"], 25, 100, 35, 750, 45],
			},
		});

		map.current.addLayer({
			id: "cluster-count",
			type: "symbol",
			source: "mbb_holders",
			filter: ["==", "cluster", true],

			layout: {
				"text-field": ["get", "point_count_abbreviated"],
				"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
				"text-size": 12,
			},
			paint: {
				"text-color": "#ffffff",
			},
		});

		// inspect a cluster on click
		map.current.on("click", "clusters", (e) => {
			const features = map.current.queryRenderedFeatures(e.point, {
				layers: ["clusters"],
			});
			const clusterId = features[0].properties.cluster_id;
			map.current
				.getSource("mbb_holders")
				.getClusterExpansionZoom(clusterId, (err, zoom) => {
					if (err) return;

					map.current.easeTo({
						center: features[0].geometry.coordinates,
						zoom: zoom + 1.55,
					});
				});
		});

		// When a click event occurs on a feature in
		// the unclustered-point layer, open a popup at
		// the location of the feature, with
		// description HTML from its properties.
		map.current.on("click", "unclustered-point", (e) => {
			const coordinates = e.features[0].geometry.coordinates.slice();

			// Ensure that if the map is zoomed out such that
			// multiple copies of the feature are visible, the
			// popup appears over the copy being pointed to.
			while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
				coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
			}
			var el = document.createElement("div");
			el.className = "marker" + e.features[0].properties.id.toString();
			el.style.backgroundImage =
				"url(" + e.features[0].properties.profile_photo + ")";
			el.style.backgroundSize = "cover";
			el.style.backgroundPosition = "center";
			el.style.width = "40px";
			el.style.height = "40px";
			el.style.borderRadius = "2px";

			new mapboxgl.Popup({
				className: "flex text-center text-sm justify-center items-center",
				closeButton: false,
				closeOnClick: true,
				closeOnMove: true,
			})
				.setLngLat(coordinates)
				.setHTML(markerDisplay(e.features[0].properties))
				.addTo(map.current);
		});

		map.current.on("mouseenter", "clusters", () => {
			map.current.getCanvas().style.cursor = "pointer";
		});
		map.current.on("mouseleave", "clusters", () => {
			map.current.getCanvas().style.cursor = "";
		});
		map.current.on("mouseenter", "unclustered-point", () => {
			map.current.getCanvas().style.cursor = "pointer";
		});
		map.current.on("mouseleave", "unclustered-point", () => {
			map.current.getCanvas().style.cursor = "";
		});
		setClustersLoaded(true);
	};

	/** Used to add pins on both desktop and mobile */
	const addPinLayer = (mobile) => {
		if (map.current.getLayer("unclustered-point"))
			map.current.removeLayer("unclustered-point");
		if (!mobile) {
			// if the user is on desktop
			map.current.addLayer({
				id: "unclustered-point",
				type: "symbol",
				source: "mbb_holders",
				filter: ["==", "cluster", false],
				layout: {
					"icon-image": ["get", "profile_photo"],
					"icon-size": 0.2, //0.04,
					"icon-rotate": 0, //180,
					// get the title name from the source's "title" property
					"text-field": ["get", "name"],
					"text-size": 14,
					"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
					"text-offset": [0, 1.75],
					"text-anchor": "top",
					"icon-allow-overlap": true,
					"text-allow-overlap": true,
					"icon-ignore-placement": true,
					"text-ignore-placement": true,
				},
				minzoom: 0,
			});
		} else {
			// mobile
			// if the user is on mobile, display pins instead
			map.current.addLayer({
				id: "unclustered-point",
				type: "symbol",
				source: "mbb_holders",
				filter: ["==", "cluster", false],
				layout: {
					"icon-image": "pin",
					"icon-size": 0.125,
					"icon-rotate": 0,
					// get the title name from the source's "title" property
					"text-field": ["get", "name"],
					"text-size": 14,
					"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
					"text-offset": [0, 0],
					"text-anchor": "top",
					"icon-allow-overlap": true,
					"text-allow-overlap": true,
					"icon-ignore-placement": true,
					"text-ignore-placement": true,
				},
				minzoom: 0,
			});
		}
	};

	// Displays a specific holder's information when their marker is clicked on the map
	// Uses the Mapbox Popup element to display the information through this HTML
	const markerDisplay = (holder) => {
		let display =
			"<div style='display: flex; justify-content: center; align-items: center'>" +
			"<img width='100' height='100' style='border-radius:4px;' alt='MBB Holder Profile Photo' src='" +
			holder.profile_photo +
			"' />" +
			"</div>" +
			"<h1 style='font-size: 16px; margin-top:6px;'><b>" +
			holder.name +
			"</b></h1>" +
			"<h1 style='padding: 0px 6px; margin-bottom: 4px;'>" +
			holder.location +
			"</h1>";
		if (holder.description && holder.description != "") {
			display +=
				"<h1 style='padding: 0px 8px;'><i>" + holder.description + "</i></h1>";
		}

		// Breaks up social media links with a new line
		if (
			(holder.twitter_username && holder.twitter_username != "") ||
			(holder.discord_username && holder.discord_username != "") ||
			(holder.github_username && holder.github_username != "")
		) {
			display += "<div style='padding-top: 12px;'></div>";
		}

		if (holder.twitter_username && holder.twitter_username != "") {
			display +=
				"<a target='blank' style='outline: none' href='https://twitter.com/" +
				holder?.twitter_username?.replace("https://twitter.com/", "") +
				"'><h1 style='color: #e41e8e'><b>Twitter @" +
				holder?.twitter_username
					?.replace("https://twitter.com/", "")
					?.replace("@", "") +
				"</h1></b></a>";
		}

		if (holder.github_username && holder.github_username != "") {
			display +=
				"<a target='blank' style='outline: none' href='https://github.com/" +
				holder?.github_username
					?.replace("https://github.com/", "")
					?.replace("@", "") +
				"'><h1 style='color: #e41e8e;'><b>Github @" +
				holder?.github_username
					?.replace("https://github.com/", "")
					?.replace("@", "") +
				"</h1></b></a>";
		}

		if (holder.discord_username && holder.discord_username != "") {
			display +=
				"<h1><b>Discord @" +
				holder?.discord_username?.replace("@", "") +
				"</b></h1>";
		}

		if (holder.skills_display != null && holder.skills_display != "") {
			display +=
				"<h1 style='text-transform: capitalize;'><b>Skills/Interests: " +
				holder.skills_display +
				"</b></h1>";
		}

		return display;
	};

	const handleOnSearch = (string, results) => {
		// onSearch will have as the first callback parameter
		// the string searched and for the second the results.
		console.log(string, results);
	};

	const handleOnHover = (result) => {
		// the item hovered
		console.log(result);
	};

	const handleOnSelect = (item) => {
		// the item selected
		console.log("HANDLE ON SELECT");
		console.log(item);

		// center and zoom map to selected item
		if (item.latitude && item.longitude) {
			map.current.flyTo({ center: [item.longitude, item.latitude], zoom: 9.5 });
		}
	};

	const handleOnFocus = () => {
		console.log("Focused");
	};

	const fuseOptions = {
		shouldSort: true,
		threshold: 0.25,
		location: 0,
		ignoreLocation: true,
		distance: 100,
		//maxPatternLength: 32,
		minMatchCharLength: 2,
		keys: [
			"name",
			"location",
			"skills_display",
			"description",
			"twitter_username",
			"discord_username",
			"github_username",
		],
	};

	const formatResult = (item) => {
		return (
			<>
				<span style={{ display: "block", textAlign: "left" }}>
					<b>
						{item.name}{" "}
						{item?.twitter_username &&
							"- (@" + item?.twitter_username?.replaceAll("@", "") + ")"}
					</b>
				</span>
				<span style={{ display: "block", textAlign: "left" }}>
					<em>
						{item?.location?.length > 35
							? item.location.substring(0, 31) + "..."
							: item.location}
					</em>
				</span>
				<span
					style={{
						display: "block",
						textAlign: "left",
						textTransform: "capitalize",
						flexWrap: "wrap",
						maxWidth: 400,
					}}
				>
					{item?.skills_display_shortened && item?.skills_display_shortened}
				</span>
			</>
		);
	};

	return (
		<div>
			<div>
				<div ref={mapContainer} style={{ height: "88vh" }}>
					<div
						style={{
							position: "absolute",
							top: windowDimensions.width <= 768 ? "6px" : "10px",
							left: "4px",
							zIndex: 10,
						}}
					>
						<div
							style={{
								width: windowDimensions.width <= 768 ? 325 : 400,
								zIndex: 10,
							}}
						>
							<ReactSearchAutocomplete
								items={mbbHolders}
								onSearch={handleOnSearch}
								onHover={handleOnHover}
								onSelect={handleOnSelect}
								onFocus={handleOnFocus}
								autoFocus
								formatResult={formatResult}
								fuseOptions={fuseOptions}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MapPage;
