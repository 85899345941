import React from "react";

export const NotFoundPage = () => {
	return (
		<div className="flex justify-center mt-10">
			<p className="font-lg font-bold text-dark-blue">404, Page not Found.</p>
		</div>
	);
};

export default NotFoundPage;
