import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import NotFoundPage from "../components/not-found/NotFoundPage";
import MapPage from "../components/map/MapPage";
import ProfilePage from "../components/profile/ProfilePage";
import LoginPage from "../components/login/LoginPage";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";

const AppRoutes = (props) => {
	return (
		<Routes>
			<Route path="/" element={<ProtectedRoute />}>
				<Route path="/" element={<MapPage />} />
				<Route path="/map" element={<MapPage />} />
				<Route path="/profile" element={<ProfilePage />} />
			</Route>

			<Route path="login" element={<PublicRoute />}>
				<Route path="/login" element={<LoginPage />} />
			</Route>

			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
};

export default AppRoutes;
