import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { holderContext } from "../components/holder-authentication/HolderAuthentication";
import { useWallet } from "@solana/wallet-adapter-react";

const PublicRoute = (props) => {
	const { connected } = useWallet();
	const { mbbHolder, newAccount, initConfig } = useContext(holderContext);

	// Navigate to map page if a user is connected and holds an MBB NFT and is trying to access the login page
	return connected ? (
		mbbHolder && initConfig ? (
			newAccount ? (
				<Navigate to="/profile" />
			) : (
				<Navigate to="/map" />
			)
		) : (
			<Outlet {...props} />
		)
	) : (
		<Outlet {...props} />
	);
};

export default PublicRoute;
