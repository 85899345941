import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import React, { useContext, useEffect, useRef } from "react";
// import AppHeader from "../header/Header";
import { useWallet } from "@solana/wallet-adapter-react";
import { holderContext } from "../../components/holder-authentication/HolderAuthentication";
import { Oval } from "react-loader-spinner";

/** Mapbox integration */
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { mapboxConfig } from "../../mapboxConfig";
mapboxgl.accessToken = mapboxConfig.accessToken;

export const LoginPage = () => {
	const { connected } = useWallet();
	const { mbbHolder, initConfig } = useContext(holderContext);

	const mapContainer = useRef(null);
	const map = useRef(null);

	/** Initializes map on page load and fetches MBB holders */
	useEffect(() => {
		if (map.current) return; // initialize map only once
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			projection: "mercator",
			style: "mapbox://styles/mapbox/streets-v12",
			center: [-30.9, 20.35], // longitude, latitude
			zoom: 2,
			dragRotate: false,
			touchZoomRotate: false,
			boxZoom: false,
			dragPan: false,
			keyboard: false,
			scrollZoom: false,
		});
	});

	return (
		<div>
			<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 pb-24">
				<div className="flex flex-col text-center justify-center items-center py-2">
					<h1 className="text-2xl font-cubano font-bold my-8 pt-6">
						Connect Your Wallet to Continue
					</h1>
					<WalletMultiButton />
					{connected && !initConfig && (
						<div className="pt-6">
							<Oval
								height={40}
								width={40}
								color="#e41e8e"
								wrapperStyle={{ display: "flex", justifyContent: "center" }}
								visible={true}
								ariaLabel="oval-loading"
								secondaryColor="#e41e8e"
								strokeWidth={2}
								strokeWidthSecondary={2}
							/>
						</div>
					)}

					{connected && !mbbHolder && initConfig && (
						<p className="text-base text-pink font-bold my-6">
							Note: You must hold at least 1 MBB NFT in your wallet to access
							the map.
						</p>
					)}
				</div>
			</div>

			<div className="opacity-30">
				<div ref={mapContainer} style={{ height: "90vh" }} />
			</div>
		</div>
	);
};

export default LoginPage;
